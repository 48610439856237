<script setup>
import {computed, ref, watch} from "vue";
import { useMenuStore } from "@/store/MenuStore";
import { storeToRefs } from "pinia";
import Cookies from "js-cookie";
import {
  COOKIE_ADDRESS_HOME,
  COOKIE_ADDRESS_SHOP,
  COOKIE_CITY,
} from "@/constants/cookies";
import BaseInput from "@/components/base/BaseInput/BaseInput.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import {useAuthStore} from "@/store/AuthStore";
import DadataAdressAutocomplete from "@/components/base/DadataAdressAutocomplete.vue";

const menuStore = useMenuStore();
const authStore = useAuthStore();
const {isAuthenticated, user} = storeToRefs(authStore);
const { cities, isLoading } = storeToRefs(menuStore);
const dialog = ref(false);
const selectedCity = ref(null);
const selectedAddress = ref(null);
const selectedDeliveryType = ref("courier");
const address = ref('');
const defaultCityId = Cookies.get(COOKIE_CITY);
const neededCity = cities.value.find((city) => city.id === defaultCityId);

watch([cities, isAuthenticated, user], ([newCities, isAuthenticated, newUser]) => {
  if (newCities && newCities.length > 0) {
    const defaultCityId = Cookies.get(COOKIE_CITY);
    const neededCity = newCities.find((city) => city.id === defaultCityId);

    if (isAuthenticated && newUser) {
      dialog.value = false;
      menuStore.setCurrentCity(newUser.city);
      Cookies.set(COOKIE_CITY, newUser.city.id);
      return;
    }

    if (neededCity) {
      menuStore.setCurrentCity(neededCity);
      dialog.value = false;
    } else {
      dialog.value = true;
    }
  }
});

const isButtonDisabled = computed(() => {
  if (!selectedCity.value) return true;
  if (selectedDeliveryType.value === 'courier') {
    return address.value === null;  // Проверка, что адрес введен
  } else {
    return !selectedAddress.value;  // Проверка, что выбран адрес самовывоза
  }
});

const handleClickButton = () => {
  if (selectedDeliveryType.value === "courier") {
    Cookies.set(COOKIE_ADDRESS_HOME, address.value.value);
  } else {
    Cookies.set(COOKIE_ADDRESS_SHOP, selectedAddress.value.id);
  }
  if (selectedCity.value) {
    Cookies.set(COOKIE_CITY, selectedCity.value.id);
    menuStore.setCurrentCity(selectedCity.value);
  }
  dialog.value = false;
  authStore.openLoginModal();
};

const handleLoginByPhone = () => {
  dialog.value = false;
  authStore.openLoginModal();
}
</script>

<template>
  <div class="text-center pa-2">
    <v-dialog
      v-model="dialog"
      persistent
      width="100%"
      max-width="578"
    >
      <v-card
        class="pt-6 pb-12 px-8"
        rounded="xl"
      >
        <v-sheet
          class="d-flex flex-column align-center justify-center"
          width="100%"
        >
          <v-sheet
            class="d-flex justify-center align-center flex-row w-100 ga-12 mb-5"
          >
            <v-sheet
              width="100%"
              max-width="160"
            >
              <v-img src="@/assets/bags.png" />
            </v-sheet>
            <v-sheet
              width="100%"
              max-width="230"
            >
              <v-img src="@/assets/logo_modal.png" />
            </v-sheet>
          </v-sheet>

          <v-sheet v-if="neededCity || selectedCity">
            <v-sheet class="pa-0 w-100 mb-4">
              <v-tabs
                v-model="selectedDeliveryType"
                color="white"
                bg-color="rgba(0, 0, 0, 0.05)"
                class="rounded-xl"
                fixed-tabs
              >
                <v-tab
                  value="courier"
                  rounded="xl"
                  selected-class="bg-customRed"
                  text="Доставка курьером"
                />

                <v-tab
                  value="pickup"
                  rounded="xl"
                  selected-class="bg-customRed"
                  text="Самовывоз из ресторана"
                />
              </v-tabs>
            </v-sheet>
            <v-sheet width="100%">
              <v-tabs-window v-model="selectedDeliveryType">
                <v-tabs-window-item value="courier">
                  <v-container
                    fluid
                    class="pa-0"
                  >
                    <v-sheet
                      class="w-100 pa-0 d-flex justify-center align-center flex-column"
                    >
                      <dadata-adress-autocomplete
                        v-model="address"
                        placeholder="Адрес"
                        label="Город, улица и дом*"
                        :prepared-city="selectedCity.title"
                      />
                    </v-sheet>
                  </v-container>
                </v-tabs-window-item>
                <v-tabs-window-item value="pickup">
                  <v-container
                    fluid
                    class="pa-0 w-100"
                  >
                    <v-sheet
                      class="w-100 pa-0 d-flex justify-center align-start flex-column"
                    >
                      <base-select
                        v-model="selectedAddress"
                        :options="selectedCity.addresses"
                        placeholder="Откуда хотите забрать заказ?"
                        :is-loading="isLoading"
                      />
                    </v-sheet>
                  </v-container>
                </v-tabs-window-item>
              </v-tabs-window>
            </v-sheet>
          </v-sheet>

          <v-sheet
            v-else
            class="pa-0 w-100"
          >
            <v-sheet
              class="w-100 pa-0 d-flex justify-center align-start flex-column"
            >
              <base-select
                v-model="selectedCity"
                :options="cities"
                placeholder="Выберите город"
                :is-loading="isLoading"
              />
            </v-sheet>
          </v-sheet>

          <v-btn
            class="rounded-xl mt-5"
            color="#e31d24"
            width="220"
            size="x-large"
            :disabled="isButtonDisabled"
            @click="handleClickButton"
          >
            ОК
          </v-btn>

          <v-btn
            class="rounded-xl mt-5 "
            color="#e31d24"
            width="auto"
            size="x-large"
            @click="handleLoginByPhone"
          >
            Войти по номеру телефона
          </v-btn>
        </v-sheet>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.list-item {
  transition: all 0.3s;
  &:hover {
    background: #e31d24;
  }
}
</style>
