<script setup>
import { useAlertStore } from '@/store/AlertStore';
import { storeToRefs } from 'pinia';

const alertStore = useAlertStore();
const { isOpen, type, message } = storeToRefs(alertStore);
const { closeAlert } = alertStore;
</script>

<template>
  <v-alert
    v-if="isOpen"
    :type="type"
    dismissible
    class="mb-4"
    position="fixed"
    location="bottom right"
    closable
    icon="false"
    @click:close="closeAlert"
  >
    {{ message }}
  </v-alert>
</template>

<style scoped>
/* Add any custom styles if needed */
</style>
