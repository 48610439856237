<template>
  <base-layout :is-loading="isLoading">
    <template #header>
      <CartHeader />
    </template>
    <v-sheet class="ma-0 pa-0 w-100">
      <v-container class="w-100 h-100 py-4">
        <cart-main />
      </v-container>
    </v-sheet>
  </base-layout>
</template>

<script setup>
import CartMain from "@/components/Cart/CartMain.vue";
import CartHeader from "@/components/Header/CartHeader.vue";
import BaseLayout from "@/components/Layouts/BaseLayout.vue";
import {useMenuStore} from "@/store/MenuStore";
import {useAuthStore} from "@/store/AuthStore";
import {storeToRefs} from "pinia";
import {computed, onMounted, ref} from "vue";

const menuStore = useMenuStore();
const authStore = useAuthStore();
const { token, user } = storeToRefs(authStore);
const isLoaded = ref(false);
const isLoading = computed(
    () => menuStore.isLoadingSections || menuStore.isLoadingCities
);

onMounted(async () => {
  if (!isLoaded.value) {
    try {
      await authStore.getTokenFromCookie();
      await menuStore.getMenuSections(token.value && user.value ? user.value.city.id : 1 );
      await menuStore.getCities();

      // Устанавливаем флаг, чтобы запросы не выполнялись снова
      isLoaded.value = true;
    } catch (error) {
      console.error(error);
    }
  }
});
</script>
