import { defineStore } from "pinia";
import apolloClient from "../../apolloClient";
import {
  CART,
  CART_ITEM_CREATE,
  WORK_SCHEDULE_DELIVERY,
  WORK_SCHEDULE_PICKUP,
} from "@/api/queries";
import {CART_CALCULATE_DELIVERY, CART_ITEM_DELETE, CART_ITEM_UPDATE, ORDER_CREATE} from "@/api/mutations";
import {useMenuStore} from "@/store/MenuStore";
import {computed, ref} from "vue";
import {useAlertStore} from "@/store/AlertStore";

export const useCartStore = defineStore('cart', () => {
  // Состояние (state)
  const items = ref([]);
  const selectedDeliveryType = ref('courier');
  const total_price = ref(0);
  const delivery_price = ref(0);
  const isLoading = ref(false);
  const schedulePickUpHours = ref([]);
  const order = ref(null);
  const bagsInfo = ref({ quantity: 0, price: 0 });
  const orderProcessingInfo = ref({ price: 0 });
  const error = ref('');

  const alertStore = useAlertStore();

  // Геттеры (getters)
  const cartTotal = computed(() => items.value.reduce((total, item) => total + item.quantity, 0));
  const cartItems = computed(() => items.value);
  const fullCartPrice = computed(() => items.value.reduce((total, item) => total + item.price, 0));
  const pickupAllowedDates = computed(() => schedulePickUpHours.value.map(item => item.date));

  // Действия (actions)
  const getCart = async () => {
    isLoading.value = true;
    try {
      const response = await apolloClient.query({
        query: CART,
      });

      const allItems = response.data.cart.items;
      const bagsItem = allItems.find(item => item.product.title === 'Пакет');
      const orderProcessingItem = allItems.find(item => item.product.title === 'Обработка заказа');

      if (bagsItem !== undefined) {
        bagsInfo.value = {
          price: bagsItem.total_price,
          quantity: bagsItem.quantity,
        };
      }
      if (orderProcessingItem !== undefined) {
        orderProcessingInfo.value = {
          price: orderProcessingItem.total_price,
        };
      }

      items.value = response.data.cart.items;
      delivery_price.value = response.data.cart.delivery_price;
      total_price.value = response.data.cart.total_price;
    } catch (err) {
      alertStore.openAlert('error', 'Ошибка загрузки корзины')
    } finally {
      isLoading.value = false;
    }
  };

  const addToCart = async (product, modifiers = [], ingredients = []) => {
    let existingItem;
    try {
      if (items.value.length !== 0) {
        existingItem = items.value.find(item => item.id === product.id);
      }

      if (existingItem) {
        existingItem.quantity++;
      } else {
        const response = await apolloClient.mutate({
          mutation: CART_ITEM_CREATE,
          variables: {
            product_id: product.id,
            quantity: product.quantity,
            modifier_items: modifiers,
            extra_ingredients: ingredients,
            part_remove_ingredients: [],
          },
        });

        items.value = response.data.cartItemCreate.items;
        delivery_price.value = response.data.cartItemCreate.delivery_price;
        total_price.value = response.data.cartItemCreate.total_price;
      }
    } catch (e) {
      alertStore.openAlert('error', 'Ошибка')
    }
  };

  const cartItemUpdate = async (cartItemId, quantity) => {
    try {
      const response = await apolloClient.mutate({
        mutation: CART_ITEM_UPDATE,
        variables: {
          id: cartItemId,
          quantity: quantity,
        },
      });

      items.value = response.data.cartItemUpdate.items;
      delivery_price.value = response.data.cartItemUpdate.delivery_price;
      total_price.value = response.data.cartItemUpdate.total_price;
    } catch (e) {
    alertStore.openAlert('error', 'Ошибка')
    }
  };

  const removeFromCart = async (cartItemId) => {
    try {
      const response = await apolloClient.mutate({
        mutation: CART_ITEM_DELETE,
        variables: {
          id: cartItemId,
        },
      });

      items.value = response.data.cartItemDelete.items;
      delivery_price.value = response.data.cartItemDelete.delivery_price;
      total_price.value = response.data.cartItemDelete.total_price;
    } catch (e) {
      alertStore.openAlert('error', 'Ошибка')
    }
  };

  const getWorkSchedulePickup = async (cityId) => {
    try {
      const response = await apolloClient.query({
        query: WORK_SCHEDULE_PICKUP,
        variables: {
          city_address_id: cityId,
        },
      });
      schedulePickUpHours.value = response.data.workSchedulePickup;
    } catch (err) {
      alertStore.openAlert('error', 'Ошибка загрузки расписания')
    }
  };

  const getWorkScheduleDelivery = async (formAddress) => {
    try {
      const response = await apolloClient.query({
        query: WORK_SCHEDULE_DELIVERY,
        variables: {
          address: formAddress,
        },
      });
      schedulePickUpHours.value = response.data.workScheduleDelivery;
    } catch (err) {
      alertStore.openAlert('error', 'Ошибка загрузки расписания')
    }
  };

  const calculateDelivery = async (formAddress, time, type, payOnDelivery = false) => {
    const deliveryData = {
      address: {
        ...formAddress,
        save: false,
      },
      time,
      type,
      pay_on_delivery: payOnDelivery,
    };

    try {
      const response = await apolloClient.mutate({
        mutation: CART_CALCULATE_DELIVERY,
        variables: {
          delivery: deliveryData,
        },
      });

      total_price.value = response.data.cartCalculateDelivery.total_price;
      delivery_price.value = response.data.cartCalculateDelivery.delivery_price;
    } catch (err) {
      alertStore.openAlert('error', 'Ошибка расчета доставки')
    }
  };

  const createOrder = async (formAddress, time, type, comment, payOnDelivery = false) => {
    try {
      const menuStore = useMenuStore(); // Используем другой стор, если нужно

      const deliveryData = {
        address: type === 'COURIER' ? {
          ...formAddress,
          save: false,
        } : null,
        time,
        type,
        pay_on_delivery: payOnDelivery,
      };

      const pickup = {
        city_address_id: menuStore.currentCity.id,
        notify_completed: true,
      };

      const response = await apolloClient.mutate({
        mutation: ORDER_CREATE,
        variables: {
          delivery: type === 'COURIER' && deliveryData,
          pickup: type === 'PICKUP' && pickup,
          payment: {
            type: 'CARDвыа',
          },
          comment: comment || '',
        },
      });

      order.value = response.data.orderCreate;
    } catch (err) {
      alertStore.openAlert('error', 'Ошибка создания заказа')
    }
  };

  const clearDeliveryTime = () => {
    schedulePickUpHours.value = [];
  }

  // Возвращаем все состояния, геттеры и действия
  return {
    items,
    selectedDeliveryType,
    total_price,
    delivery_price,
    isLoading,
    schedulePickUpHours,
    order,
    bagsInfo,
    orderProcessingInfo,
    error,

    // Геттеры
    cartTotal,
    cartItems,
    fullCartPrice,
    pickupAllowedDates,

    // Действия
    getCart,
    addToCart,
    cartItemUpdate,
    removeFromCart,
    getWorkSchedulePickup,
    getWorkScheduleDelivery,
    calculateDelivery,
    createOrder,
    clearDeliveryTime
  };
});

