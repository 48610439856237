<template>
  <base-sheet>
    <base-title class="mb-4">
      Итого
    </base-title>
    <v-container
      v-for="(item, index) in dataEntries"
      :key="index"
      class="pa-0 d-flex justify-space-between align-center"
    >
      <check-item :item="item" />
    </v-container>
  </base-sheet>
</template>

<script setup>
import BaseSheet from "@/components/base/BaseSheet/BaseSheet.vue";
import BaseTitle from "@/components/base/BaseTitle/BaseTitle.vue";
import { computed } from "vue";
import CheckItem from "@/components/Cart/CartCheck/CheckItem/CheckItem.vue";
import {useCartStore} from "@/store/CartStore";
import {storeToRefs} from "pinia";

const cartStore = useCartStore();
const { total_price, delivery_price, bagsInfo, orderProcessingInfo } = storeToRefs(cartStore)

const data = computed(() => {
  return {
    itemsPrice: {
      name: "Сумма",
      value: `${total_price.value} ₽`,
    },
    bags: {
      name: `Пакет x ${bagsInfo.value.quantity}`,
      value: `${bagsInfo.value.price} ₽`,
    },
    makingOrder: {
      name: "Обработка заказа",
      value: `${orderProcessingInfo.value.price} ₽`,
    },
    deliveryPrice: {
      name: "Доставка",
      value: `${delivery_price.value} ₽`,
    },
    totalPrice: {
      name: "Итого",
      value: `${total_price.value} ₽`,
    },
  }
})

const dataEntries = computed(() =>
  Object.entries(data.value).map(([key, value]) => ({ key, ...value }))
);
</script>
