import { defineStore } from 'pinia';
import apolloClient from "../../apolloClient";
import {
    GET_ORDERS
} from '@/api/queries';
export const useProfileStore = defineStore('profileStore', {
    state: () => ({
        orders: null,
    }),

    actions: {
        async getOrders() {
            this.isLoading = true;
            try {
                const response = await apolloClient.query({
                    query: GET_ORDERS,
                });

                this.orders = response.data.orders;
            } catch (err) {
                this.error = "Failed to fetch cart";
            } finally {
                this.isLoading = false;
            }
        },
    }
});
