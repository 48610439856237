<template>
  <v-sheet
      v-if="!!currentCity"
    elevation="4"
    height="500"
    rounded="xl"
    class="position-relative"
    width="100%"
  >
    <v-sheet rounded="xl" class="pa-0 fill-height overflow-hidden">
      <swiper
        @swiper="onSwiper"
        ref="mySwiper"
        :slides-per-view="1"
        :space-between="0"
        style="height: 100%"
      >
        <swiper-slide
          v-for="(slide, i) in currentCity.banners"
          :key="i"
          style="height: 100%"
        >
          <v-sheet class="fill-height pa-0">
            <v-img cover height="100%" :src="slide.url" />
          </v-sheet>
        </swiper-slide>
      </swiper>
    </v-sheet>
    <v-container class="custom-navigation">
      <v-btn
        class="custom-button prev-button"
        @click="slidePrev"
        variant="elevated"
        density="compact"
        icon="mdi-chevron-left"
        size="x-large"
        color="white"
      ></v-btn>
      <v-btn
        class="custom-button prev-button"
        @click="slideNext"
        variant="elevated"
        density="compact"
        icon="mdi-chevron-right"
        size="x-large"
        color="white"
      ></v-btn>
    </v-container>
  </v-sheet>
</template>
<script setup>
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";
import {storeToRefs} from "pinia";
import {useMenuStore} from "@/store/MenuStore";
const mySwiper = ref(null);
const {currentCity} = storeToRefs(useMenuStore())
const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

const slideNext = () => {
  if (mySwiper.value) {
    mySwiper.value.slideNext();
  }
};

const slidePrev = () => {
  if (mySwiper.value) {
    mySwiper.value.slidePrev();
  }
};

// const slides = [
//   "https://tomyumbar.ru/img/new_slide/banner_n_1.webp",
//   "https://tomyumbar.ru/img/new_slide/banner_6.jpg",
//   "https://tomyumbar.ru/img/new_slide/banner_n_3.webp",
//   "https://tomyumbar.ru/img/new_slide/1720162040_banner_vremya_1-png.png",
//   "https://tomyumbar.ru/img/new_slide/1720174252_banner_udovol-stvie_1-png.png",
// ];
</script>

<style scoped>
.custom-button {
  pointer-events: all;
}

.custom-navigation {
  position: absolute;
  top: 0;
  left: -22px;
  z-index: 10;
  width: calc(100% + 44px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  padding: 0;
}
</style>
