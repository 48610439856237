<template>
  <v-sheet
    elevation="0"
    :class="noBottomPadding ? 'mb-0' : 'mb-8'"
    class="pa-0 w-100 d-flex flex-column"
    rounded
  >
    <v-label
      v-if="label"
      class="text-black opacity-100 semibold mb-0"
    >
      {{
        label
      }}
    </v-label>
    <v-text-field
      v-bind="attrs"
      ref="inputField"
      v-model="innerValue"
      class="pa-0"
      variant="underlined"
      :class="centeredText ? 'text-center' : ''"
      hide-details="auto"
      @input="updateValue($event.target.value)"
    />
    <v-card-subtitle
      v-if="errorMessages.length > 0"
      class="text-customRed"
    >
      {{
        errorMessages
      }}
    </v-card-subtitle>
  </v-sheet>
</template>

<script setup>
import {onBeforeUnmount, onMounted, ref, toRefs, useAttrs, watch} from "vue";
import { VLabel } from "vuetify/components";
import IMask from 'imask';
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: "",
  },
  errorMessages: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  customMask: {
    type: String,
    required: false,
  },
  noBottomPadding: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    required: false,
    default: "underlined"
  },
  centeredText: {
    type: Boolean,
    required: false,
  }
});

const emit = defineEmits(["update:modelValue"]);
const attrs = useAttrs();
const { modelValue, label } = toRefs(props);
const inputMask = ref(null); // Реф для хранения инстанса маски
const innerValue = ref(modelValue.value);
const inputField = ref(null);

watch(modelValue, (newValue) => {
  innerValue.value = newValue;
});
// Function to handle input events and update the parent component
const updateValue = (value) => {
  innerValue.value = value;
  emit("update:modelValue", value);
};

// onMounted(() => {
//   if (props.customMask && inputField.value) {
//     inputMask.value = IMask(inputField.value, {
//       mask: props.customMask,
//       lazy: true, // Показывать маску даже если поле пустое
//       definitions: {
//         '0': /[0-9]/,
//       },
//     });
//
//     // Обновляем v-model при изменении значения в маске
//     inputMask.value.on('accept', () => {
//       emit('update:modelValue', inputMask.value.value);
//     });
//   }
// });
//
// onBeforeUnmount(() => {
//   if (inputMask.value) {
//     inputMask.value.destroy();
//   }
// });

</script>

<style scoped>
.input {
  all: unset;
  border: none;
  border-bottom: 1px solid #000000;
  cursor: pointer;
  width: auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #2b2b2b;
}
</style>
