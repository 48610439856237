<template>
  <div
    ref="scrollContainer"
    class="container"
  >
    <SectionItem
      v-for="item in itemRefs"
      :id="item.id"
      :key="item.id"
      ref="contentBlocks"
      class="section"
      :section="item"
      :title="item.title"
    />
  </div>
  <SelectIngredientsModal />
  <LoginModal />
</template>

<script setup>
import { useMenuStore } from "@/store/MenuStore";
import { ref, onMounted, onUnmounted } from "vue";
import SectionItem from "@/components/Menu/SectionItem/SectionItem.vue";
import { storeToRefs } from "pinia";
import SelectIngredientsModal from "@/components/modals/SelectIngredientsModal.vue";
import LoginModal from "@/components/Auth/LoginModal.vue";

const menuStoreData = useMenuStore();
const itemRefs = storeToRefs(menuStoreData).sections;
const contentBlocks = ref([]);

onMounted(() => {
  const sections = document.querySelectorAll(".section");
  const options = {
    threshold: 0.5,
  };
  const observer = new IntersectionObserver(function (entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        menuStoreData.changeActiveSection(entry.target.id);
      }
    });
  }, options);
  sections.forEach((section) => {
    observer.observe(section);
  });
});
</script>

<style scoped></style>
