import {defineStore, storeToRefs} from "pinia";
import {mockSections} from "@/constants/mock";
import {CATEGORIES, CITIES} from "@/api/queries";
import apolloClient from "../../apolloClient";
import {useCartStore} from "@/store/CartStore";
import Cookies from "js-cookie";
import {COOKIE_CITY} from "@/constants/cookies";

export const useMenuStore = defineStore("MenuStore", {
  state: () => ({
    sections: [],
    activeSection: mockSections[0].id,
    cities: [],
    currentCity: null,
    isLoadingSections: true,
    isLoadingCities: true,
    ingredientsModalState: {
      isOpen: false,
      product: null,
    },
  }),
  getters: {
    activeSectionId: (state) => state.activeSection,
    totalCartPrice: (state) => {
      return state.sections.reduce((total, section) => {
        const sectionTotal = section.products.reduce((sectionSum, product) => {
          return sectionSum + product.price * product.quantity;
        }, 0);
        return total + sectionTotal;
      }, 0);
    },
  },
  actions: {
    async getMenuSections(cityId = 1) {
      this.isLoadingSections = true;
      try {
        const {items} = storeToRefs(useCartStore());
        const response = await apolloClient.query({
          query: CATEGORIES,
          variables: {
            city_id: cityId,
          },
        });
        this.sections = response.data.categories.map((section) => {
          return {
            ...section,
            products: section.products.map((product) => {
              const existingProduct = items.value.find(
                  (item) => item.product.id === product.id
              );
              if (existingProduct) {
                return {
                  ...product,
                  quantity: existingProduct.quantity,
                };
              } else {
                return {
                  ...product,
                  quantity: 0,
                };
              }
            }),
          };
        });
        this.activeSection = response.data.categories[0];
      } catch (err) {
        this.error = "Failed to fetch products";
      } finally {
        this.isLoadingSections = false;
      }
    },
    async getCities() {
      this.isLoadingCities = true;
      try {
        const response = await apolloClient.query({
          query: CITIES,
        });
        this.cities = response.data.cities;
        const cookieCity = Cookies.get(COOKIE_CITY);
        this.currentCity = response.data.cities.find((city) =>{
          return cookieCity ?  city.id === cookieCity :  city.id === "1"
        });
      } catch (err) {
        this.error = "Failed to fetch products";
      } finally {
        this.isLoadingCities = false;
      }
    },
    async setCurrentCity(city) {
      await this.getMenuSections(city.id)
      Cookies.set(COOKIE_CITY, city.id);
      this.currentCity = {
        ...city,
      };

    },
    changeActiveSection(section) {
      if (section) {
        this.activeSection = section;
      }
    },
    async addProductToCart(newProduct, modifiers = [], ingredients = []) {
      try {
        const cartStore = useCartStore();
        await cartStore.addToCart(newProduct, modifiers, ingredients);
        this.sections.map((section) => {
          return section.products.map((product) => {
            if (product.id === newProduct.id) {
              return {
                ...product,
                quantity: product.quantity++,
              };
            }
          });
        });
      } catch(e) {
        console.error(e)
      }
    },
    removeProductFromCart(newProduct) {
      const cartStore = useCartStore();
      cartStore.removeItemFromLocalStorageCart(newProduct.id);
      this.sections.map((section) => {
        return section.products.map((product) => {
          if (product.id === newProduct.id) {
            return product.quantity === 0
              ? undefined
              : {
                  ...product,
                  quantity: product.quantity--,
                };
          }
        });
      });
    },
    fullRemoveProductFromCart(newProduct) {
      for (const section of this.sections) {
        const product = section.products.find((p) => p.id === newProduct.id);
        if (product) {
          product.quantity = 0;
          break;
        }
      }
    },
    openIngredientsModal(product) {
      this.ingredientsModalState = {
        isOpen: true,
        product,
      };
    },
    closeIngredientsModal() {
      this.ingredientsModalState.isOpen = false;
    },
    addIngredientsToCartProduct(productId, newIngredients) {
      const storedCart = JSON.parse(localStorage.getItem("cart"));
      const existingItem = storedCart.find((item) => item.id === productId);
      if (storedCart && existingItem) {
        localStorage.setItem(
            "cart",
            JSON.stringify([
              ...storedCart.map((product) =>
                  product.id === existingItem.id
                      ? { ...existingItem, ingredients: [...newIngredients] }
                      : product
              ),
            ])
        );
      }
    },
    addModifiersToCartProduct(productId, modifiers) {
      const storedCart = JSON.parse(localStorage.getItem("cart"));
      const existingItem = storedCart.find((item) => item.id === productId);

      if (storedCart && existingItem && modifiers.length !== 0) {
        localStorage.setItem(
            "cart",
            JSON.stringify([
              ...storedCart.map((product) =>
                  product.id === existingItem.id
                      ? { ...existingItem, modifiers: [...modifiers] }
                      : product
              ),
            ])
        );
      }
    },
    updateSections(sections) {
      this.sections = sections;
    },
  },
});
