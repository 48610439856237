<script setup>
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import {watch} from "vue";
import Cookies from "js-cookie";

const menuStore = useMenuStore();
const {cities, currentCity, isLoading} = storeToRefs(menuStore);

const handleSelectCity = (newVal) => {
  menuStore.setCurrentCity(newVal)
}

</script>

<template>
  <div class="d-flex flex-column container">
    <div class="text-subtitle-1 font-weight-bold title">
      КОНТАКТЫ
    </div>
    <div class="d-flex flex-row align-items-center justify-center w-75">
      <v-select
        v-model="currentCity"
        hide-details
        flat
        class="custom-select pa-0"
        :items="cities"
        bg-color="white"
        tile
        return-object
        style="padding: 0"
        density="compact"
        placeholder="Город"
        :loading="isLoading"
        @update:model-value="handleSelectCity"
      >
        <template #prepend-inner>
          <v-icon
            class="custom-icon"
            size="small"
          >
            mdi-map-marker
          </v-icon>
        </template>

        <div
          v-for="item in cities"
          :key="item"
          class="text-subtitle-1"
        >
          {{ item.title }}
        </div>
      </v-select>
    </div>
  </div>
</template>

<style scoped>
.container {
  min-width: 200px;
}
.title {
  font-size: 16px;
}
</style>
