<script setup>

</script>

<template>
  <v-sheet class="pa-0 ma-o default-profile-title">
    Текущий заказ
  </v-sheet>
</template>

<style scoped>

</style>
