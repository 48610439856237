<script setup>
import {computed, ref} from "vue";
import MenuButton from "@/components/base/MenuButton.vue";
import { useMenuStore } from "@/store/MenuStore";
import {useAuthStore} from "@/store/AuthStore";
import {storeToRefs} from "pinia";
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const menuStore = useMenuStore();
const authStore = useAuthStore();
const {isAuthenticated} = storeToRefs(authStore);
const selectedModifiers = ref([]);
const isItemCreation = ref(false);
const updateSelectedModifier = (modifier, selectedItem) => {
  const existingModifier = selectedModifiers.value.find(mod => mod.modifier.id === modifier.id);

  if (existingModifier) {
    // Если модификатор уже выбран, обновляем его
    existingModifier.selectedItem = selectedItem;
  } else {
    // Иначе добавляем новый модификатор с выбранным элементом
    selectedModifiers.value.push({ modifier, selectedItem });
  }
};
const updatedModifiers = computed(() => {
  return props.product.modifiers.map((modifier) => {
    const existingSelection = selectedModifiers.value.find(
        (mod) => mod.modifier.id === modifier.id
    );
    return {
      ...modifier,
      selected: existingSelection ? existingSelection.selectedItem : null,
      items: modifier.items.map((item) => {
        return {
          ...item,
          title: `${item.title} (${item.price}₽)`,
        };
      }),
    };
  });
});

const handleAddProductToCart = async () => {
  if (!isAuthenticated.value) {
    authStore.openLoginModal();
    return;
  }
  if (props.product.ingredients.length !== 0) {
    menuStore.openIngredientsModal(props.product);
    return;
  }
  const modifiers = selectedModifiers.value.map((modifier) => modifier.selectedItem)
  isItemCreation.value = true;
  const newProduct = {...props.product, quantity: props.product.quantity + 1};
  await menuStore.addProductToCart(newProduct, modifiers);
  isItemCreation.value = false;
};
const handleRemoveItemFromCart = () => {
  menuStore.removeProductFromCart(props.product);
};
</script>

<template>
  <v-card
    width="full"
    flat
  >
    <v-row
      no-gutters
      class="py-3 px-0"
    >
      <v-col
        cols="6"
        class="px-4"
      >
        <v-img
          width="100%"
          height="auto"
          min-height="150"
          :src="product.image_full_url"
          cover
          rounded
        />
        <v-card-subtitle
          class="mt-4 text-center d-flex justify-center text-h6 text-black font-weight-bold opacity-100"
        >
          <span>{{ product.price }} ₽ {{ product.meta_weight }} г.</span>
        </v-card-subtitle>
      </v-col>
      <v-col cols="6">
        <v-sheet
          v-if="product.flags.length > 0"
          class="d-flex flex-row align-center w-100 pa-0 ga-2 mb-1"
        >
          <v-sheet
            v-for="flag in product.flags"
            :key="flag.id"
          >
            <v-img
              :alt="flag.title"
              width="40"
              height="20"
              :src="flag.image_url"
            />
            <v-tooltip
              activator="parent"
              location="top"
            >
              {{ flag.title }}
            </v-tooltip>
          </v-sheet>
        </v-sheet>
        <v-card-title
          class="text-h5 px-0 no-ellipsis font-weight-bold text-uppercase"
        >
          {{ product.title }}
        </v-card-title>

        <v-card-text class="text-caption custom-line-height px-0">
          {{ product.description }}
        </v-card-text>

        <v-card-text
          class="text-caption custom-line-height-2 px-0 semibold pb-2"
        >
          Ккал:&nbsp;{{ product.meta_calories }}, белки:&nbsp;{{
            product.meta_proteins
          }}&nbsp;г., жиры:&nbsp;{{ product.meta_fat }}&nbsp;г.,
          углеводы:&nbsp;{{ product.meta_carbohydrates }}&nbsp;г.
        </v-card-text>

        <v-col v-if="updatedModifiers.length > 0">
          <v-row
            v-for="modifier in updatedModifiers"
            :key="modifier.id"
            class="mb-2"
          >
            <v-select
              v-if="modifier.items"
              v-model="modifier.selected"
              class="font-weight-light text-subtitle-2 pa-0"
              tile
              flat
              hide-details
              :items="modifier.items"
              density="compact"
              :placeholder="modifier.title"
              variant="outlined"
              item-value="id"
              item-text="title"
              @update:model-value="(selectedId) => updateSelectedModifier(modifier, selectedId)"
            >
              <template #selection="{ item }">
                <v-sheet class="pa-0 font-weight-light text-subtitle-2">
                  {{ item.title }}
                </v-sheet>
              </template>
            </v-select>
          </v-row>
        </v-col>

        <MenuButton
          :add-product="handleAddProductToCart"
          :remove-product="handleRemoveItemFromCart"
          :product="product"
          :is-loading="isItemCreation"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped>
.custom-line-height {
  line-height: 1.5;
}

.custom-line-height-2 {
  line-height: 1.2;
}

.no-ellipsis {
  white-space: normal; /* Разрешить перенос текста на несколько строк */
  text-overflow: unset; /* Убрать многоточие */
  overflow: unset;
  line-height: 1.2;
}
</style>
