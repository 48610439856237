<script setup>
import {onBeforeMount, ref} from "vue";
import { storeToRefs } from "pinia";
import BaseButton from "@/components/base/BaseButton.vue";
import {useCartStore} from "@/store/CartStore";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  }
})

const cartStore = useCartStore();
const {order} = storeToRefs(cartStore);
const emit = defineEmits(['update:isOpen']);

const handleClickPay = () => {
  emit('update:isOpen', false);
  window.open(order.value.payment_url, "_blank");
}
</script>

<template>
  <div class="text-center pa-2">
    <v-dialog
      :close-on-back="true"
      :model-value="isOpen"
      persistent
      width="100%"
      max-width="578"
      @update:model-value="(value) => $emit('update:isOpen', value)"
    >
      <v-card
        class="pt-6 pb-12 px-8"
        rounded="xl"
      >
        <v-sheet
          class="d-flex flex-column align-center justify-center"
          width="100%"
        >
          <v-sheet
            class="mb-4"
            width="100%"
            max-width="230"
          >
            <v-img src="@/assets/logo_modal.png" />
          </v-sheet>

          <v-sheet class="text-center semibold text-h6 mb-5">
            Спасибо за заявку!<br>
            Ваш заказ {{ order.order_number }}
          </v-sheet>

          <base-button
            text="Оплатить"
            max-width="200"
            @click="handleClickPay"
          />
        </v-sheet>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.list-item {
  transition: all 0.3s;
  &:hover {
    background: #e31d24;
  }
}
</style>
