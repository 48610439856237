<script setup>
  import OrderTableItem from "@/components/Profile/OrdersHistory/OrderTableItem.vue";
  import {format} from "date-fns";
  import {computed} from "vue";

  const props = defineProps({
    order: {
      type: Object,
      required: true,
    }
  })

  const formattedDate = computed(() => {
    return format(new Date(props.order.completion_time), "dd.MM.yyyy")
  })

</script>

<template>
  <v-sheet class="w-100 pa-0 ma-0 d-flex flex-row h-100">
    <v-sheet
      class="d-flex flex-row justify-space-between flex-nowrap w-100 pa-0 ma-0 rounded-shadow cursor-pointer"
    >
      <order-table-item :has-divider="true">
        {{ formattedDate }}
      </order-table-item>
      <order-table-item :has-divider="true">
        {{ order.order_number }}
      </order-table-item>
      <order-table-item :has-divider="true">
        {{ order.order_number }}
      </order-table-item>
      <order-table-item :has-divider="true">
        {{ order.total_price }}₽
      </order-table-item>
      <order-table-item :has-divider="true">
        {{ order.status_title }}
      </order-table-item>
    </v-sheet>
    <v-sheet class="d-flex justify-center align-center semibold py-2">
      <v-sheet class="bg-customRed d-flex justify-center align-center rounded-be rounded-te text-subtitle-2 cursor-pointer floating-button">
        Повторить
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.rounded-shadow {
  border-radius: 8px;
  box-shadow: 0 0 7px 0 rgba(194, 194, 194, 0.5);
}

.floating-button {
  position: absolute;
  top: calc(50% - 30px);
  right: -101px;
  padding: 0 16px;
  height: 80%;
}
</style>
