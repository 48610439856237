import {ApolloClient, InMemoryCache, createHttpLink, from} from '@apollo/client/core';
import {appConfig} from "./config";
import {setContext} from "@apollo/client/link/context";
import {useAuthStore} from "@/store/AuthStore";

const httpLink = createHttpLink({
    uri: `${appConfig.API_URL}/graphql`,
});

const authHeader = setContext(() => {
    // Используйте Pinia для получения токена
    const authStore = useAuthStore();
    const token = authStore.token; // Предполагается, что в хранилище есть поле 'token'

    return {
        headers: {
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
    link: from([authHeader, httpLink]),
    cache,
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            notifyOnNetworkStatusChange: true,
        },
    },

});

export default apolloClient;
