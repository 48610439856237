<template>
  <v-skeleton-loader
    :loading="isLoading"
    type="image"
  >
    <v-row class="mb-12">
      <v-card-title class="text-h3">
        {{ props.section.title }}
      </v-card-title>
      <v-divider
        class="border-opacity-100 my-4"
        color="black"
      />
      <v-col
        v-for="product in props.section.products"
        :key="product.id"
        cols="12"
        sm="6"
        class="pa-0 py-2"
      >
        <MenuItem :product="product" />
      </v-col>
    </v-row>
  </v-skeleton-loader>
</template>

<script setup>
import MenuItem from "@/components/Menu/MenuItem/MenuItem.vue";
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";
const menuStoreData = useMenuStore();
const isLoading = storeToRefs(menuStoreData).isLoading;

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
})
</script>
