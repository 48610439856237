<script setup>
import { onMounted} from "vue";
import {useAuthStore} from "@/store/AuthStore";
import {storeToRefs} from "pinia";
import BaseLayout from "@/components/Layouts/BaseLayout.vue";
import LeftAside from "@/components/Profile/components/LeftAside.vue";
import CartHeader from "@/components/Header/CartHeader.vue";
import LoginModal from "@/components/Auth/LoginModal.vue";
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

// onMounted(async () => {
//   if (!isLoaded.value) {
//     try {
//       await authStore.getTokenFromCookie();
//       isLoaded.value = true;
//     } catch (error) {
//       console.error(error);
//     }
//   }
// });

onMounted(async () => {
  try {
    const isAuth = await authStore.checkAuth();
    if (!isAuth) {
      authStore.openLoginModal()
    }
  } catch (error) {
    console.error(error);
  }
});
</script>

<template>
  <base-layout :is-loading="false">
    <template #header>
      <CartHeader />
    </template>
    <v-sheet
      v-if="user !== null"
      class="d-flex flex-column w-100  pt-10"
    >
      <v-sheet
        class="text-h3 w-100 mb-12 text-customRed"
        style="{font-weight: 300}"
      >
        <span class="semibold">{{ user.name }},</span> рады вас видеть!
      </v-sheet>


      <v-row class="ga-12 flex-nowrap">
        <v-col cols="3">
          <left-aside />
        </v-col>
        <v-col cols="9">
          <v-sheet class="pa-0 ma-0">
            <router-view />
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>
    <LoginModal />
  </base-layout>
</template>

<style>
.default-profile-title {
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0.02em;
  text-align: left;

}
</style>
