<template>
  <div
    class="position-sticky w-100 top-0 border-b bg-white pa-5 container d-flex justify-center align-center"
  >
    <v-row align="center" class="w-100">
      <v-col v-if="isVisible">
        <HeaderContacts />
      </v-col>
      <v-col cols="1">
        <div class="my-auto">
          <v-img width="30" height="30" src="@/assets/yandex.png" />
        </div>
      </v-col>
      <v-col v-if="isVisible">
        <div>
          <v-img width="260px" height="110px" src="@/assets/Logo_Tom_Yam.svg" />
        </div>
      </v-col>
      <v-col v-if="!isVisible" cols="8">
        <MenuListNav />
      </v-col>
      <v-col v-if="isVisible">
        <HeaderHours />
      </v-col>
      <v-col cols="3">
        <HeaderCart />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MenuListNav from "@/components/Header/MenuListNav/MenuListNav.vue";
import HeaderContacts from "@/components/Header/HeaderContacts/HeaderContacts.vue";
import HeaderHours from "@/components/Header/HeaderHours/HeaderHours.vue";
import HeaderCart from "@/components/Header/HeaderCart/HeaderCart.vue";

export default {
  name: "HeaderComponent",
  components: { HeaderContacts, HeaderHours, HeaderCart, MenuListNav },

  data: () => ({
    isVisible: true,
  }),
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const currentScrollPosition = window.scrollY;

      // Если пользователь прокручивает вниз, скрыть элемент
      this.isVisible = currentScrollPosition <= 0;
    },
  },
};
</script>

<style scoped>
.container {
  z-index: 1000;
}
</style>
