<template>
  <v-row class="w-100">
    <v-col ref="scrollContainer">
      <div class="d-flex flex-row justify-center  gc-4 gr-1 flex-wrap">
        <div
          v-for="section in menuStoreData.sections"
          :key="section.id"
          class="section d-inline-flex flex-row align-items-center justify-center ga-2 cursor-pointer"
          @click="handleClickMenuItem(section)"
        >
          <div
            :class="{
              active: menuStoreData.activeSection === section.id,
            }"
            class="text-subtitle-2 text-uppercase rounded-xl my-auto py-1 px-2 semibold"
          >
            {{ section.title }}
          </div>
          <div
            class="h-50 bg-black my-auto"
            style="width: 1px"
          />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script setup>
import { useMenuStore } from "@/store/MenuStore";
import { useRouter } from "vue-router";

// Подключаем store и router
const menuStoreData = useMenuStore();
const router = useRouter();

// Создаем ref для mockSections
// const sections = ref(mockSections);

// Обрабатываем клик по элементу меню
const handleClickMenuItem = (item) => {
  router.push({
    path: "/",
    hash: `#${item.id}`,
  });
};
</script>

<style scoped>
.active {
  color: #fff;
  background: #e31d24;
}
</style>
