<script setup>
import { defineProps } from "vue";
import CountToggler from "@/components/base/CountToggler/CountToggler.vue";
import { useCartStore } from "@/store/CartStore";
const cartStore = useCartStore();

const handleCLickPlus = () => {
  cartStore.cartItemUpdate(props.product.id, props.product.quantity + 1);
};

const handleCLickMinus = () => {
  if (props.product.quantity <= 1) {
    cartStore.removeFromCart(props.product.id);
    return;
  }
  cartStore.cartItemUpdate(props.product.id, props.product.quantity - 1);
};

const handleCLickCross = () => {
  cartStore.removeFromCart(props.product.id);
};

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});
</script>

<template>
  <v-sheet
    rounded="5"
    class="w-100 pa-4 ga-4 elevation-1 rounded-xl d-flex flex-row align-start justify-space-between"
  >
    <v-sheet
      width="175"
      height="170"
      class="d-flex justify-center align-center"
    >
      <v-img
        width="175"
        height="auto"
        :src="props.product.product.image_full_url"
        cover
        rounded
      />
    </v-sheet>
    <v-sheet class="flex-1-0-0 h-100">
      <v-card-title
        class="text-subtitle-1 font-weight-medium pa-0 text-uppercase mb-2 text-pre-wrap"
      >
        {{ props.product.product.title }}
      </v-card-title>

      <v-sheet class="pa-0 mb-2">
        <v-sheet
          v-for="modifier in props.product.modifier_items"
          :key="modifier.id"
          class="d-flex justify-space-between align-center flex-row"
        >
          <v-sheet class="text-grey-lighten-1 text-subtitle-2">
            {{ modifier.title }}
          </v-sheet>
          <v-sheet class="text-grey-lighten-1 text-subtitle-2">
            {{ modifier.price }}₽
          </v-sheet>
        </v-sheet>
      </v-sheet>

      <count-toggler
        :handle-click-minus="handleCLickMinus"
        :handle-click-plus="handleCLickPlus"
        :value="props.product.quantity"
        :loading="true"
      />
    </v-sheet>
    <v-sheet
      class="d-flex align-end justify-space-between flex-column h-100 "
    >
      <v-icon
        color="gray"
        class="text-grey-darken-1 cursor-pointer"
        icon="mdi-close"
        size="large"
        @click="handleCLickCross"
      />
      <v-sheet
        class="pa-0 d-flex flex-column align-end justify-end h-100 "
      >
        <div class="text-h6">
          {{ props.product.product.meta_weight }}г
        </div>
        <div class="text-h5 font-weight-bold">
          {{ props.product.product.price }}₽
        </div>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>
