<template>
  <v-container class="pa-0 w-100">
    <v-row

      align="center"
    >
      <v-col
        cols="3"
        class="semibold pa-2"
      >
        {{ props.device.title }}
      </v-col>
      <v-col
        class="d-flex align-center justify-center pa-2"
        cols="7"
      >
        <count-toggler
          :handle-click-minus="handleCLickMinus"
          :handle-click-plus="handleCLickPlus"
          :value="cartItemDeviceQuantity"
        />
      </v-col>
      <v-col
        cols="2"
        class="semibold pa-2"
      >
        {{ deviceTotalPrice ? deviceTotalPrice : 0 }}₽
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import CountToggler from "@/components/base/CountToggler/CountToggler.vue";
import {computed} from "vue";
import {useCartStore} from "@/store/CartStore";
import {storeToRefs} from "pinia";
const props = defineProps({
  device: {
    type: Object,
    required: true,
  },
});
const cartStore = useCartStore();
const {cartItems} = storeToRefs(cartStore);
const cartItemDevice = computed(() => cartItems.value.find((item) => item.product.id === props.device.id))
const cartItemDeviceQuantity = computed(() => cartItemDevice.value ? cartItemDevice.value.quantity : 0);
const deviceTotalPrice = computed(() => props.device.price * cartItemDeviceQuantity.value)


const handleCLickPlus = () => {
  cartStore.addDeviceToCart(props.device, cartItemDeviceQuantity.value + 1);
};

const handleCLickMinus = () => {
  if (cartItemDeviceQuantity.value !== 0 && cartItemDevice.value) {
    cartStore.cartItemUpdate(cartItemDevice.value.id, cartItemDeviceQuantity.value - 1);
  }
};

</script>

<style scoped></style>
