import {createApp, h, provide} from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "@/router";
import { createPinia } from "pinia";
import { mask } from "vue-the-mask";
import {DefaultApolloClient} from "@vue/apollo-composable";
import apolloClient from "../apolloClient";

loadFonts();
const pinia = createPinia();

createApp({
    setup () {
        provide(DefaultApolloClient, apolloClient)
    },

    render: () => h(App),
})
  .use(pinia)
  .use(router)
  .use(vuetify)
  .directive("mask", mask)
  // .use(VueTheMask)
  .mount("#app");
