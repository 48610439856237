<script setup>
import {onBeforeMount} from "vue";
import {useProfileStore} from "@/store/ProfileStore";
import {storeToRefs} from "pinia";
import OrderListItem from "@/components/Profile/OrdersHistory/OrderListItem.vue";
import OrderTableItem from "@/components/Profile/OrdersHistory/OrderTableItem.vue";
import OrderItemsList from "@/components/Profile/OrdersHistory/OrderItemsList.vue";

const profileStore = useProfileStore()
const {orders} = storeToRefs(profileStore);

onBeforeMount(() => {
  profileStore.getOrders();
})
</script>

<template>
  <v-sheet class="pa-0 ma-o default-profile-title mb-10">
    История заказов
  </v-sheet>
  <v-sheet class="pa-0 ma-o w-100 d-flex flex-row">
    <v-sheet
      class="w-100 d-flex flex-row justify-start flex-nowrap pa-0 ma-0 mb-6 rounded-shadow"
    >
      <order-table-item
        :is-header="true"
        :has-divider="true"
      >
        Дата заказа
      </order-table-item>
      <order-table-item
        :is-header="true"
        :has-divider="true"
      >
        Номер заказа
      </order-table-item>
      <order-table-item
        :is-header="true"
        :has-divider="true"
      >
        Адрес доставки
      </order-table-item>
      <order-table-item
        :is-header="true"
        :has-divider="true"
      >
        Сумма
      </order-table-item>
      <order-table-item
        :is-header="true"
        :has-divider="true"
      >
        Статус
      </order-table-item>
    </v-sheet>
    <v-sheet class="d-flex justify-center align-center semibold px-4 mb-6">
      Повторить?
    </v-sheet>
  </v-sheet>
  <v-expansion-panels v-if="orders && orders.data.length > 0">
    <v-expansion-panel
      v-for="order in orders.data"
      :key="order.id"
      hide-actions
      class="pa-0 mb-4"
      height="75px"
    >
      <template #text>
        <order-items-list :order="order" />
      </template>
      <template
        #title
      >
        <order-list-item
          :key="order.id"
          :order="order"
        />
      </template>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style>
.rounded-shadow {
  border-radius: 8px;
  box-shadow: 0 0 7px 0 rgba(194, 194, 194, 0.5);
}

.v-expansion-panel-title {
  padding: 0 !important;
}
</style>
