<template>
  <v-sheet class="w-100 pa-3 rounded-xl">
    <v-row>
      <v-col cols="2">
        <v-sheet
          style="width: 90px; height: 90px"
          border="customRed md"
          class="rounded-circle bg-transparent pa-2 border-solid border-opacity-100"
        >
          <v-img class="" width="100%" src="@/assets/warn.svg"></v-img>
        </v-sheet>
      </v-col>
      <v-col cols="10">
        <v-sheet class="pl-2 d-flex flex-column ga-4">
          <v-sheet class="text-subtitle-1 font-weight-regular text-w lh-1">
            Обращаем Ваше внимание, что в наших блюдах содержатся продукты
            <span class="text-customRed"
              >(мидии в створках, креветки в панцире, оригинальные специи и др.)
              и аллергены,</span
            >
            требующие особого внимания для Вашей безопасности. Будьте осторожны!
          </v-sheet>
          <v-sheet class="bg-transparent mt-5">
            <v-img class="" width="100%" src="@/assets/perec.svg"></v-img>
          </v-sheet>
        </v-sheet>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script setup></script>

<style scoped>
.lh-1 {
  line-height: 1.3 !important;
}
</style>
