import { parse, isEqual } from 'date-fns';

export const log = (value) => {
    console.log(JSON.parse(JSON.stringify(value)));
}

export const isDatesEqual = (date1, format1, date2, format2) => {
    // Преобразуем первую дату с использованием первого формата
    const parsedDate1 = parse(date1, format1, new Date());

    // Преобразуем вторую дату с использованием второго формата
    const parsedDate2 = parse(date2, format2, new Date());

    // Сравниваем даты с использованием isEqual
    return isEqual(parsedDate1, parsedDate2);
}

export const combineDateTime = (timeString, dateObject) => {
    if (!timeString || !dateObject) return null;
    // Разбиваем время на часы и минуты
    const [hours, minutes] = timeString.split(':').map(Number);

    // Устанавливаем часы и минуты в объекте даты
    dateObject.setHours(hours);
    dateObject.setMinutes(minutes);
    dateObject.setSeconds(0);  // Секунды обнуляем

    // Преобразуем в формат ISO 8601 (UTC)
    return dateObject.toISOString();
}
