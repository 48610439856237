<script setup>
import CustomRouterLink from "@/components/Profile/components/CustomRouterLink.vue";

const socialLinksData = [
  {
    icon:  require('@/assets/IconPhone.svg'),
    link: 'https://www.facebook.com',
  },
  {
    icon:  require('@/assets/IconEmail.svg'),
    link: 'https://www.facebook.com',
  },
  {
    icon:  require('@/assets/iconWhatsApp.svg'),
    link: 'https://www.facebook.com',
  },
  {
    icon:  require('@/assets/iconTg.svg'),
    link: 'https://www.facebook.com',
  }
]
</script>

<template>
  <v-sheet class="d-flex flex-column ga-2 mb-7">
    <custom-router-link to="/profile/order">
      Текущий заказ
    </custom-router-link>
    <custom-router-link to="/profile/history">
      История заказов
    </custom-router-link>
    <custom-router-link to="/profile/edit">
      Редактирование профиля
    </custom-router-link>
  </v-sheet>
  <v-sheet class="pa-0 ma-0 d-flex flex-column align-center">
    <v-sheet class="text-subtitle-1 ma-0 mb-3 semibold">
      Связаться с нами
    </v-sheet>
    <v-sheet class="pa-0 ma-0 d-flex flex-row align-center justify-space-between ga-5">
      <a
        v-for="social in socialLinksData"
        :key="social"
        target="_blank"
        :href="social.link"
        class="socialLink"
      >
        <v-img
          width="20"
          height="20"
          :src="social.icon"
        />
      </a>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.socialLink {
  text-decoration: none;
  background: #e31d24;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    background: #ec4148;
  }
}
</style>
