import { defineStore } from 'pinia';
import apolloClient from "../../apolloClient";
import {VERIFICATION_CODE, AUTHORIZATION_TOKEN, LOGOUT, PROFILE_DELETE, USER, CITIES, CART} from '@/api/queries';
import Cookies from "js-cookie";
import {COOKIE_AUTH_TOKEN, COOKIE_CITY} from "@/constants/cookies";
import {boolean} from "yup";
export const useAuthStore = defineStore('authStore', {
    state: () => ({
        token: null, // Хранит JWT токен пользователя
        isAuthenticated: false, // Флаг авторизации пользователя
        verificationCodeId: null, // ID верификационного кода
        verificationCode: null,
        isLoading: true, // Флаг загрузки для асинхронных запросов
        isAuthModalOpen: false,
        user: null,
    }),

    actions: {
        // Запрос кода верификации
        async requestVerificationCode(phoneNumber, deviceName) {
            this.isLoading = true;
            try {
                const response = await apolloClient.mutate({
                    mutation: VERIFICATION_CODE,
                    variables: {
                        phone_number: phoneNumber,
                        device_name: deviceName,
                    },
                });

                // Сохранение verification_code_id для дальнейшего использования
                this.verificationCodeId = response.data.verificationCode.verification_code_id;
                return response.data.verificationCode.is_new_user;
            } catch (error) {
                console.error('Ошибка при запросе кода верификации:', error);
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        // Авторизация и получение токена
        async login(verificationCode, cityId = null, name = null, items = []) {
            this.isLoading = true;

            try {
                const response = await apolloClient.mutate({
                    mutation: AUTHORIZATION_TOKEN,
                    variables: {
                        verification_id: this.verificationCodeId,
                        verification_code: verificationCode,
                        city_id: cityId,
                        name: 'userName',
                        items: items,
                    },
                });

                // Сохраняем полученный токен
                this.token = response.data.login.token;
                this.isAuthenticated = true;
                Cookies.set(COOKIE_AUTH_TOKEN, response.data.login.token);
                Cookies.set(COOKIE_CITY, cityId);
                await this.getUserInfo();
            } catch (error) {
                console.error('Ошибка при авторизации:', error);
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        async getUserInfo() {
            this.isLoading = true;
            try {
                const response = await apolloClient.query({
                    query: USER,
                });
                this.user = response.data.me;

            } catch (error) {
                console.error('Ошибка при получении информации о пользователе:', error);
                throw error;
            } finally {
                this.isLoading = false;
            }
        },


        // Логаут
        async logout() {
            this.isLoading = true;

            try {
                const response = await apolloClient.mutate({
                    mutation: LOGOUT,
                });

                if (response.data.logout.success) {
                    this.token = null;
                    this.isAuthenticated = false;
                    // Очистить токен из cookies или localStorage, если нужно
                }
            } catch (error) {
                console.error('Ошибка при выходе из системы:', error);
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        // Удаление профиля
        async deleteProfile() {
            this.isLoading = true;

            try {
                const response = await apolloClient.mutate({
                    mutation: PROFILE_DELETE,
                });

                if (response.data.profileDelete.success) {
                    this.token = null;
                    this.isAuthenticated = false;
                    // Очистить токен и данные пользователя, если нужно
                }
            } catch (error) {
                console.error('Ошибка при удалении профиля:', error);
                throw error;
            } finally {
                this.isLoading = false;
            }
        },

        async checkAuth(){
            try {
                const token = Cookies.get(COOKIE_AUTH_TOKEN);

                if (token) {
                    this.token = token;
                    await this.getUserInfo();
                    this.isAuthenticated = true;
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                console.error(error);
            }
        },

        async getTokenFromCookie() {
            const token = Cookies.get(COOKIE_AUTH_TOKEN);
            if (token) {
                this.token = token
                this.isAuthenticated = true;
                await this.getUserInfo();
            }
        },

        openLoginModal() {
            this.isAuthModalOpen = true;
        },

        closeLoginModal() {
            this.isAuthModalOpen = false;
        }
    },
});
