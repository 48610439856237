<script setup>
import { ref } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import BaseInput from "@/components/base/BaseInput/BaseInput.vue";
import * as yup from "yup";
import {useField, useForm} from "vee-validate";
import BaseButton from "@/components/base/BaseButton.vue"; // Импортируем Pinia store для авторизации

// Стор авторизации (предположим, что вы уже создали стор с методами requestVerificationCode и login)
const authStore = useAuthStore();
const menuStore = useMenuStore();

const { isAuthModalOpen } = storeToRefs(authStore)

// Локальные переменные для формы
const isLoading = ref(false);
const currentStep = ref(1);
const selectedCity = ref(null);
const { cities } = storeToRefs(menuStore);

const schema = yup.object({
  phoneNumber: yup
      .string()
      .required('Введите номер телефона')
      .matches(/^\+7\d{10}$/, 'Номер телефона должен быть в формате +79991234567'),
  verificationCode: yup
      .string()
      .when('currentStep', {
        is: 2, // Проверка кода только на втором шаге
        then: yup.string().required('Введите код верификации'),
      }),
});

const { errors } = useForm({
  validationSchema: schema,
});

// Определяем поля формы
const { value: phoneNumber } = useField('phoneNumber');
const { value: verificationCode } = useField('verificationCode');

// Шаг 1: Отправить запрос на получение кода верификации
const requestVerificationCode = async () => {
  isLoading.value = true;
  try {
    // Вызываем метод из Pinia store для отправки номера телефона
    await authStore.requestVerificationCode(phoneNumber.value, 'web');
    currentStep.value = 2; // Переходим на второй шаг (ввод кода)
  } catch (error) {
    console.error('Ошибка запроса кода верификации:', error);
    alert('Не удалось отправить код');
  } finally {
    isLoading.value = false;
  }
};

// Шаг 2: Авторизация по коду верификации
const login = async () => {
  isLoading.value = true;
  try {
    await authStore.login(verificationCode.value, '' );
    authStore.closeLoginModal();
  } catch (error) {
    console.error('Ошибка при авторизации:', error);
  } finally {
    isLoading.value = false;
  }
};

// Сброс формы и возврат на первый шаг
const resetForm = () => {
  phoneNumber.value = '';
  verificationCode.value = '';
  currentStep.value = 1;
};
</script>

<template>
  <v-dialog
    v-model="isAuthModalOpen"
    width="100%"
    max-width="500"
  >
    <v-sheet
      rounded="xl"
      class="pt-6 pb-12 px-8 d-flex justify-center align-center flex-column"
    >
      <v-sheet
        width="100%"
        max-width="260"
      >
        <v-img src="@/assets/Logo_Tom_Yam.svg" />
      </v-sheet>
      <v-card-text class="text-center text-customRed text-h4 font-weight-bold">
        {{ currentStep === 1 ? "Вход" : "Введите код верификации" }}
      </v-card-text>

      <v-form
        v-if="currentStep === 1"
        ref="form"
        class="w-100"
      >
        <v-sheet
          class="d-flex justify-center align-center flex-column ga-4 px-4"
        >
          <base-input
            v-model="phoneNumber"
            placeholder="+79991234567"
            no-bottom-padding
            :error-messages="errors.phoneNumber"
            custom-mask="+{7}(000)000-00-00"
          />
          <!--          <base-select-->
          <!--            v-model="selectedCity"-->
          <!--            :options="cities"-->
          <!--            placeholder="Выберите город"-->
          <!--            :is-loading="isLoading"-->
          <!--            class="mb-4"-->
          <!--          />-->
          <v-sheet
            max-width="220"
            class="pa-0 ma-0"
          >
            <base-button
              :loading="isLoading"
              text="Получить код"
              @click="requestVerificationCode"
            />
          </v-sheet>
        </v-sheet>
      </v-form>

      <v-form
        v-else
        ref="form"
      >
        <base-input
          v-model="verificationCode"
          placeholder="Код"
          :error-messages="errors.verificationCode"
          centered-text
        />
        <v-sheet
          max-width="220"
          class="pa-0 ma-0"
        >
          <base-button
            :loading="isLoading"
            text="Войти"
            @click="login"
          />
        </v-sheet>
      </v-form>
    </v-sheet>
  </v-dialog>
</template>

<style scoped>

</style>
