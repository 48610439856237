import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/pages/HomePage.vue";
import CartPage from "@/pages/CartPage.vue";
import ProfilePage from "@/pages/ProfilePage.vue";
import CurrentOrder from "@/components/Profile/CurrentOrder/CurrentOrder.vue";
import EditProfile from "@/components/Profile/EditProfile/EditProfile.vue";
import OrdersHistory from "@/components/Profile/OrdersHistory/OrdersHistory.vue";
const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    exact: true,

  },
  {
    path: "/cart",
    name: "CartPage",
    component: CartPage,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
    children: [
      {
        path: 'order',
        name: 'CurrentOrder',
        component: CurrentOrder,
      },
      {
        path: 'history',
        name: 'OrderHistory',
        component: OrdersHistory,
      },
      {
        path: 'edit',
        name: 'EditProfile',
        component: EditProfile,
      },
      {
        path: '',
        redirect: { name: 'CurrentOrder' }, // перенаправление по умолчанию на текущий заказ
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        el: to.hash,
        behavior: "smooth",
        top: 100,
      };
    }
  },
});

export default router;
