<script setup>
const props = defineProps({
  onClick: {
    type: Function,
  },
  text: {
    type: String,
  },
  maxWidth: {
    type: Number,
  },
});
</script>

<template>
  <v-btn
    class="rounded-xl semibold"
    color="#e31d24"
    width="100%"
    size="x-large"
    :max-width="props.maxWidth"
    @click="props.onClick"
  >
    <v-sheet class="semibold bg-transparent">
      {{ props.text }}
    </v-sheet>
  </v-btn>
</template>
