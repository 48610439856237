<script setup>
import CartItemsList from "@/components/Cart/CartItemsList/CartItemsList.vue";
import CartHeader from "@/components/Cart/CartHeader/CartHeader.vue";
import CartDevices from "@/components/Cart/CartDevices/CartDevices.vue";
import CartCheck from "@/components/Cart/CartCheck/CartCheck.vue";
import CartComment from "@/components/Cart/CartComment/CartComment.vue";
import CartAdditional from "@/components/Cart/CartAdditional/CartAdditional.vue";
import CartForm from "@/components/Cart/CartForm/CartForm.vue";
import {onMounted, ref, watch} from "vue";
import { useCartStore } from "@/store/CartStore";
import LoginModal from "@/components/Auth/LoginModal.vue";
import {useAuthStore} from "@/store/AuthStore";
import {storeToRefs} from "pinia";
import CreatedOrderModal from "@/components/modals/CreatedOrderModal.vue";

const cartStore = useCartStore();
const authStore = useAuthStore();

const { isAuthenticated } = storeToRefs(authStore);
const { order } = storeToRefs(cartStore);
const isOpenPaymentModal = ref(false);

watch(order, (newVal)=> {
  if (newVal !== null) isOpenPaymentModal.value = true;
})

onMounted(async () => {
  try {
    await authStore.checkAuth();
    if (!isAuthenticated.value) {
      authStore.openLoginModal()
    } else {
      await cartStore.getCart();
    }
  } catch (error) {
    console.error(error);
  }
});
</script>

<template>
  <v-sheet
    rounded
    elevation="0"
    class="pa-0 d-flex align-start flex-column w-100"
  >
    <v-col cols="12">
      <v-row class="pa-0">
        <cart-header />
      </v-row>
      <v-row class="">
        <v-col cols="6">
          <cart-items-list />
          <v-sheet class="pa-0 mt-4">
            <cart-additional />
          </v-sheet>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="6">
              <cart-devices />
            </v-col>
            <v-col cols="6">
              <cart-check />
            </v-col>
          </v-row>
          <v-row>
            <cart-comment />
          </v-row>
          <v-row>
            <cart-form />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-sheet>
  <LoginModal />
  <created-order-modal v-model:isOpen="isOpenPaymentModal" />
</template>
