<template>
  <base-layout :is-loading="isLoading">
    <template #header>
      <HeaderComponent />
    </template>
    <MainPage />
  </base-layout>
</template>

<script setup>
import MainPage from "@/components/Home/MainComponent.vue";
import BaseLayout from "@/components/Layouts/BaseLayout.vue";
import HeaderComponent from "@/components/Header/HeaderComponent.vue";
import {computed, onMounted, ref} from "vue";
import {useMenuStore} from "@/store/MenuStore";
import {useAuthStore} from "@/store/AuthStore";
import {storeToRefs} from "pinia";

const menuStore = useMenuStore();
const authStore = useAuthStore();
const { token, user } = storeToRefs(authStore);
const isLoaded = ref(false);
const isLoading = computed(
    () => menuStore.isLoadingSections || menuStore.isLoadingCities
);

onMounted(async () => {
  if (!isLoaded.value) {
    try {
      await authStore.getTokenFromCookie();
      await menuStore.getMenuSections(token.value && user.value ? user.value.city.id : 1 );
      await menuStore.getCities();

      // Устанавливаем флаг, чтобы запросы не выполнялись снова
      isLoaded.value = true;
    } catch (error) {
      console.error(error);
    }
  }
});
</script>
