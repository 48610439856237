// src/store/alertStore.ts
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAlertStore = defineStore('alert', () => {
    const isOpen = ref(false);
    const type = ref('error'); // Типы можно задавать 'success', 'info', 'warning', 'error'
    const message = ref('');

    const openAlert = (alertType, alertMessage) => {
        type.value = alertType;
        message.value = alertMessage;
        isOpen.value = true;

        setTimeout(() => {
            closeAlert();
        }, 3000);
    };

    const closeAlert = () => {
        isOpen.value = false;
        message.value = '';
        type.value = '';
    };

    return {
        isOpen,
        type,
        message,
        openAlert,
        closeAlert,
    };
});
